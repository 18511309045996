import style from "./sidemenu.module.scss";
import { useEffect, useRef } from "react";

function useOutsideClick(ref, callback) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(); // Dış tıklamada callback'i çalıştır
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

const SideMenu = ({ categoryList, selected, click, closeClick }) => {
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    if (closeClick) {
      closeClick();
    }
  });

  return (
    <div ref={ref} className={style.sideMenu}>
      <div className={style.wrapper}>
        {categoryList.slice(0, 2).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
      <div className={style.wrapper}>
        {categoryList.slice(2, 5).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
      <div className={style.wrapper}>
        {categoryList.slice(5, 6).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
      <div className={style.wrapper}>
        {categoryList.slice(6, 7).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SideMenu;
