import style from "./workcard.module.scss";
import Slider from "react-slick";
import { useRef, useState, useEffect } from "react";
import { changeElementWidth } from "../../redux/actions/elementWidthAction";
import { useDispatch } from "react-redux";

function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

const WorkCard = ({
  image,
  info,
  title,
  subTitle,
  mobile,
  update,
  index,
  isIndex,
  currentWidth,
  sliderRef,
  dotButtonClick,
  upArrow,
  downArrow,
}) => {
  const [isLoad, setLoad] = useState(false);

  const dispatch = useDispatch();
  const [slide, setSlide] = useState({
    slideIndex: 0,
    updateCount: 0,
  });
  const settings = {
    dots: false,
    speed: 350,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    accessibility: false,

    beforeChange: (current, next) => setSlide({ slideIndex: next }),
  };

  function handler({ key }) {
    if (ARROW_UP.includes(String(key))) {
      if (slide.slideIndex === 0) {
        return false;
      } else {
        upArrow(slide.slideIndex - 1);
      }
    } else if (ARROW_DOWN.includes(String(key))) {
      if (slide.slideIndex + 1 === image.length) {
        return false;
      } else {
        downArrow(slide.slideIndex + 1);
      }
    } else if (ARROW_LEFT.includes(String(key))) {
    } else if (ARROW_RIGHT.includes(String(key))) {
    }
  }

  const ARROW_UP = ["38", "ArrowUp"];
  const ARROW_DOWN = ["40", "ArrowDown"];
  const ARROW_LEFT = ["37", "ArrowLeft"];
  const ARROW_RIGHT = ["39", "ArrowRight"];

  useEventListener("keydown", handler);

  const itemsRef = useRef([]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, image.length);
  }, [image]);

  useEffect(() => {
    if (isIndex && itemsRef && isLoad) {
      dispatch(
        changeElementWidth(
          itemsRef.current[slide.slideIndex].width > 0
            ? itemsRef.current[slide.slideIndex].width
            : 1200
        )
      );
    }
  }, [isIndex, slide, isLoad, dispatch]);

  return (
    <>
      <div className={style.workCard}>
        {mobile ? (
          image.slice(0, 2).map((item, index) => {
            return item.fileType === "image" ? (
              <>
                <div className={style.imageCard}>
                  <img
                    onLoad={() => setLoad(true)}
                    src={item.fileUrl}
                    alt="work"
                  />
                </div>
              </>
            ) : item.fileType === "video" ? (
              <div className={style.imageCard}>
                <video
                  ref={(el) => (itemsRef.current[index] = el)}
                  onLoadedData={() => setLoad(true)}
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src={item.fileUrl} type="video/mp4" />
                </video>
              </div>
            ) : null;
          })
        ) : (
          <>
            {image && image.length > 1 ? (
              <Slider ref={sliderRef} {...settings}>
                {image.map((item, index) => {
                  return item.fileType === "image" ? (
                    <>
                      <div className={style.imageCard}>
                        <img
                          ref={(el) => (itemsRef.current[index] = el)}
                          src={item.fileUrl}
                          alt="work"
                          onLoad={() => setLoad(true)}
                        />
                      </div>
                    </>
                  ) : item.fileType === "video" ? (
                    <div className={style.imageCard}>
                      <video
                        ref={(el) => (itemsRef.current[index] = el)}
                        onLoad={() => setLoad(true)}
                        autoPlay
                        muted
                        loop
                      >
                        <source src={item.fileUrl} type="video/mp4" />
                      </video>
                    </div>
                  ) : null;
                })}
              </Slider>
            ) : image[0].fileType === "image" ? (
              <div className={style.imageCard}>
                <img
                  ref={(el) => (itemsRef.current[0] = el)}
                  src={image[0].fileUrl}
                  alt="work"
                  onLoad={() => setLoad(true)}
                />
              </div>
            ) : image[0].fileType === "video" ? (
              <div className={style.imageCard}>
                <video
                  ref={(el) => (itemsRef.current[0] = el)}
                  onLoad={() => setLoad(true)}
                  autoPlay
                  muted
                  loop
                >
                  <source src={image[0].fileUrl} type="video/mp4" />
                </video>
              </div>
            ) : null}
          </>
        )}
        <div className={style.infoCardWrapper}>
          <div className={style.infoCard}>
            <h3>{title}</h3>
            <h4>{subTitle}</h4>
            <div className={style.emptySpace}></div>
            {info
              ? info.map((i) => {
                  return i.type === "text" ? (
                    <p>{i.content}</p>
                  ) : i.type === "space" ? (
                    <div className={style.emptySpace}></div>
                  ) : i.type === "link" ? (
                    <a href={i.link} rel="noreferrer" target={"_blank"}>
                      {i.content}
                    </a>
                  ) : null;
                })
              : null}
          </div>
          {image && image.length > 1 ? (
            <div className={style.infoNavWrapper}>
              {image.map((i, index) => {
                return (
                  <div
                    onClick={() => dotButtonClick(index)}
                    className={
                      slide.slideIndex === index ? style.selected : null
                    }
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default WorkCard;
