import { useState, useEffect, useRef } from "react";
// import Carousel from '@brainhubeu/react-carousel';
import Slider from "react-slick";
import axios from "axios";
import { BackendUrl } from "../../constants/ApiConfig";
import { ApiRoutes } from "../../constants/ApiRoutes";
import style from "./home.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import {
  WorkCard,
  SideMenu,
  BottomNav,
  Header,
  AboutBar,
} from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import KeyboardAlertImage from "../../assets/img/keyboard-alert.png";

function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

const slugify = (text) => {
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "") // remove non-alphanumeric chars
    .replace(/\s/gi, "-") // convert spaces to dashes
    .replace(/[-]+/gi, "-") // trim repeated dashes
    .toLowerCase();
};

const Home = () => {
  const [workData, setWorkData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [slide, setSlide] = useState({
    slideIndex: 0,
    updateCount: 0,
  });
  const sliderRef = useRef();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const elementWidth = useSelector((state) => state.elementWidth.elementWidth);
  const [alert, setAlert] = useState(true);
  const [isSideMenu, setSideMenu] = useState(false);

  const [mobileCategory, setMobileCategory] = useState();

  useEffect(() => {
    axios
      .get(`${BackendUrl}${ApiRoutes.website.get}`)
      .then((res) => {
        console.log(res.data);
        setCategoryData(res.data.categoryList);
        setMobileCategory({
          title: res.data.categoryList[0].category.title,
        });
        setWorkData(
          res.data.categoryList
            .map((i) => i.category.works)
            .flat()
            .filter((item) => item.work.isLive)
            .map((item) => {
              return {
                ...item.work,
                link: slugify(item.work.title),
              };
            })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 350,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: false,
    arrows: false,

    beforeChange: (current, next) => setSlide({ slideIndex: next }),
  };

  const handleOnClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const ARROW_UP = ["38", "ArrowUp"];
  const ARROW_DOWN = ["40", "ArrowDown"];
  const ARROW_LEFT = ["37", "ArrowLeft"];
  const ARROW_RIGHT = ["39", "ArrowRight"];

  function handler({ key }) {
    if (ARROW_UP.includes(String(key))) {
      console.log("Yukarıya Basıldı");
    } else if (ARROW_DOWN.includes(String(key))) {
      console.log("Aşağıya Basıldı");
    } else if (ARROW_LEFT.includes(String(key))) {
      if (slide.slideIndex === 0) {
        return false;
      } else {
        handleOnClick(slide.slideIndex - 1);
      }
    } else if (ARROW_RIGHT.includes(String(key))) {
      if (slide.slideIndex + 1 === workData.length) {
        return false;
      } else {
        handleOnClick(slide.slideIndex + 1);
      }
    }
  }

  useEventListener("keydown", handler);

  useEffect(() => {
    if (workData && width > 481) {
      navigate(`/${workData[slide.slideIndex].link}`);
    }
  }, [slide.slideIndex, workData, navigate, width]);

  useEffect(() => {
    if (workData && width > 481) {
      const findIndex = workData.findIndex(
        (item) => item.link === location.pathname.split("/")[1]
      );
      sliderRef.current.slickGoTo(findIndex);
    }
  }, [workData, location.pathname, width]);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  }, []);

  const workRef = useRef([]);

  useEffect(() => {
    if (workData) {
      workRef.current = workRef.current.slice(0, workData.length);
      console.log(workRef.current);
    }
  }, [workData]);

  return width ? (
    <>
      <Header click={() => setSideMenu(true)} mobile={width < 481} />
      {workData ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>E S Kibele Yarman - {workData[slide.slideIndex].title}</title>
        </Helmet>
      ) : null}
      {width < 481 && workData ? (
        <>
          {categoryData && workData && isSideMenu ? (
            <SideMenu
              selected={mobileCategory}
              categoryList={categoryData}
              closeClick={() => setSideMenu(false)}
              click={(e) => {
                setMobileCategory({
                  title: e,
                });

                const element = document.getElementById(e);
                if (element) {
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
                setSideMenu(false);
                // const findWork = workData.findIndex(
                //   (item) => item.category.title === e
                // );
                // sliderRef.current.slickGoTo(findWork);
              }}
            />
          ) : null}
          <div className={style.workWrapper}>
            {workData.map((item, index) => {
              const filterData = workData.filter(
                (i) => i.category.title === item.category.title
              );

              return (
                <span
                  id={
                    filterData[0].title === item.title
                      ? item.category.title
                      : ""
                  }
                >
                  <WorkCard
                    image={item.files}
                    info={item.info}
                    title={item.title}
                    subTitle={item.subTitle}
                    mobile
                  />
                </span>
              );
            })}
          </div>
        </>
      ) : (
        <>
          {width < 481 ? null : <AboutBar />}
          {workData ? (
            <>
              {" "}
              <button
                onClick={() => {
                  if (slide.slideIndex === 0) {
                    return false;
                  } else {
                    handleOnClick(slide.slideIndex - 1);
                  }
                }}
                className={style.prev}
                style={{
                  width: `${(width - 320 - elementWidth) / 2 - 50}px`,
                  opacity: slide.slideIndex === 0 ? 0.3 : 1,
                  // width: `${elementWidth / 2}px`,
                }}
              >
                <svg class="flickity-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 90,80 L 90,65 L 90,50  L 90,35 L 90,20 Z"
                    class="arrow"
                  ></path>
                </svg>
              </button>
              <img
                style={{
                  width: `${(width - 320 - elementWidth) / 2 - 50}px`,
                  opacity: alert && slide.slideIndex === 0 ? 1 : 0,
                  display: slide.slideIndex === 0 ? "flex" : "none",
                }}
                className={style.keyboardAlert}
                src={KeyboardAlertImage}
                alt="keyboard-alert"
              />
              <button
                onClick={() => {
                  if (slide.slideIndex + 1 === workData.length) {
                    return false;
                  } else {
                    handleOnClick(slide.slideIndex + 1);
                  }
                }}
                className={style.next}
                style={{
                  width: `${(width - 320 - elementWidth) / 2 - 50}px`,
                  opacity: slide.slideIndex + 1 === workData.length ? 0.3 : 1,
                  // width: `${elementWidth / 2}px`,
                }}
              >
                <svg class="flickity-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 90,80 L 90,65 L 90,50  L 90,35 L 90,20 Z"
                    class="arrow"
                    transform="translate(100, 100) rotate(180) "
                  ></path>
                </svg>
              </button>
            </>
          ) : null}
          <div
            style={{
              width: "100vw",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            {categoryData && workData ? (
              <SideMenu
                selected={workData[slide.slideIndex].category}
                categoryList={categoryData}
                click={(e) => {
                  const findWork = workData.findIndex(
                    (item) => item.category.title === e
                  );
                  sliderRef.current.slickGoTo(findWork);
                }}
              />
            ) : null}

            {workData ? (
              <>
                <Slider ref={sliderRef} {...settings}>
                  {workData.map((item, index) => {
                    return (
                      <>
                        <WorkCard
                          image={item.files}
                          info={item.info}
                          title={item.title}
                          subTitle={item.subTitle}
                          update={slide}
                          index={index}
                          isIndex={slide.slideIndex === index}
                          sliderRef={(el) => (workRef.current[index] = el)}
                          dotButtonClick={(e) => {
                            if (slide.slideIndex === index) {
                              workRef.current[index].slickGoTo(e);
                            }
                          }}
                          upArrow={(e) => {
                            if (slide.slideIndex === index) {
                              workRef.current[index].slickGoTo(e);
                            }
                          }}
                          downArrow={(e) => {
                            if (slide.slideIndex === index) {
                              workRef.current[index].slickGoTo(e);
                            }
                          }}
                        />
                      </>
                    );
                  })}
                </Slider>
              </>
            ) : null}
            {workData ? (
              <BottomNav
                click={(e) => sliderRef.current.slickGoTo(e)}
                selected={slide.slideIndex}
                data={workData}
              />
            ) : null}
          </div>
        </>
      )}
    </>
  ) : null;
};

export default Home;
