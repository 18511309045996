import style from "./header.module.scss";
import Logo from "../../assets/img/logo.svg";
import { useLocation } from "react-router-dom";

const Header = ({ mobile, about, click }) => {
  const location = useLocation();

  return (
    <header className={`${style.header} ${about ? style.aboutHeader : null}`}>
      {mobile ? (
        <div className={style.wrapper}>
          {location.pathname === "/about" ? (
            <a href="/" className={style.mobileNav}>
              WORK
            </a>
          ) : (
            <button onClick={click} className={style.mobileNav}>
              WORK
            </button>
          )}
          <a className={style.logo} href="/">
            <img src={Logo} alt="logo" />
          </a>
          <a href="/about" className={style.mobileNav}>
            ABOUT
          </a>
        </div>
      ) : (
        <a href="/" className={style.wrapper}>
          <img src={Logo} alt="logo" />
          <span>E S KIBELE YARMAN</span>
        </a>
      )}
    </header>
  );
};

export default Header;
